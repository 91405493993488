import React from "react"
import { GetServerSideProps } from "next"
import { useComponents } from "../../hooks/use-components"
import { getDataAdapter } from "../../data-adapters/data-adapters"
import { getBoard } from "../../lib/helpers"
import { ErrorPageProps } from "../../lib/types/shared"
import { JobsPageProps } from "../../data-adapters/common"
import { getErrorCode } from "../../lib/helpers"

const JobsPage: React.FC<JobsPageProps> = ({
  companies,
  companiesCount,
  jobs,
  jobsCount,
  meta,
}) => {
  const {
    Layout,
    PartnersWithMostJobs,
    JobList,
    JobsFilter,
    Hero,
    Meta,
    Tracking,
  } = useComponents()
  return (
    <Layout>
      <Hero />
      <Meta {...meta} />
      <Tracking />
      <PartnersWithMostJobs
        companies={companies}
        companiesCount={companiesCount}
      />
      <JobsFilter />
      <JobList jobs={jobs} jobsCount={jobsCount} />
    </Layout>
  )
}

export default JobsPage

export const getServerSideProps: GetServerSideProps<
  JobsPageProps | ErrorPageProps
> = async context => {
  try {
    const board = getBoard(context.req)
    const adapter = await getDataAdapter(board)
    return adapter.getJobsPageProps(context)
  } catch (err) {
    return { props: { errorCode: getErrorCode(err) } }
  }
}
